<template>
    <div>
        <el-row>
             <el-col :span="16" class="logins"> <div ></div></el-col>
             <el-col :span="8" class="logins1">
                
                    

                     <el-form ref="form" :model="form" label-width="80px" style="margin:0 auto">
                          <img src="@/assets/logo.png" alt="" style="    width: 220px;
    height: 80px;
    margin-bottom: 50px;">
                          <div class="dis"> 
                              <p style="width:100px;font-weight: 500;border-right: 1px solid #DFE0E8;height:40px;line-height:40px;
color: #293460;">账户ID</p> <input placeholder="请输入账号ID"  v-model="form.name" style="height:40px"></div>
                          <div class="dis">
                             <p style="width:100px;font-weight: 500;border-right: 1px solid #DFE0E8;height:40px;line-height:40px;
color: #293460;">账户密码</p> 
                          <el-input placeholder="请输入密码"  v-model="form.password" show-password style="height:40px;width: 220px;"></el-input>

</div>
                       
                          <div class="dis">  <p style="overflow:hidden;width:100px;font-weight: 500;border-right: 1px solid #DFE0E8;height:40px;line-height:40px;
color: #293460;"> <img :src=pic @click="changeico()" alt="" style="max-width:100%"></p> <input placeholder="请输入验证码"  @change="handlerPageNo" v-model="form.code" style="height:40px"></div>
                    <!-- <div class="" style="text-align: left;cursor: pointer;"  @click="open">
                        <el-checkbox v-model="checked" name="type"></el-checkbox> <span style="font-size:14px">我已仔细阅读并知晓 <a style="color:#0034FF">《调律猫钢琴维护平台协议》</a></span>
                    </div> -->
                   <el-button type="primary" @click="go()" style="width:100%;margin-top:20px">登录</el-button>
                   <p style="margin-top:200px;font-size:12px">版权所有© 深圳市调律猫钢琴科技有限公司 
<a href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备20060617号</a></p>
                   <el-dialog
                class="l"
                   :show-close=false
                                :close-on-press-escape=false
                            :close-on-click-modal=false
                                title="调律猫钢琴维护平台与用户的协议(请下拉阅读后点击确定)"
                                :visible.sync="dialogVisible"
                                width="60%"
                               >             
                           
                         
<p>1.1本《调律猫钢琴维护平台与用户的协议》（以下简称《协议》）是用户（以下简称“用户”）与调律猫钢琴维护平台（以下简称“平台”）之间关于使用此系统软件进行注册、使用、客户管理、资金结算等功能，以及平台提供的相关服务、责任划分等所订立的协议。
</p>
<p>1.2平台软件系统为用户提供基于互联网、小程序、后台管控等现代化信息平台服务，为获得平台授权，用户应认真阅读、充分理解本《协议》中的各条款，特别涉及对用户的权利限制条款、约定争议解决方式、司法管辖、法律适用的条款。请您审慎阅读，一经点击“同意并接受该协议”点击登录按键后，即表示同意接受本《协议》；如您不接受本《协议》的部分或全部内容，请您不要点击“登录”按键。（无民事行为能力人谢绝适用该系统、限制民事行为能力人应在法定监护人监护下阅读、理解及适用本系统）。除非您接受本《协议》的所有条款，否则您无权下载、安装或使用本软件以及相关服务，您的下载、安装、使用、账号获取和登录等行为将视为对本《协议》的接受，并同意接受本《协议》各项条款的约束。
</p>

<p>1.3 用户资料经平台审核通过后，平台将给予用户一个系统后台，该系统后台归平台所有，用户拥有使用权，平台给予提供唯一授权码，此授权码仅限本人登录使用，禁止赠与、借用、租用、转让或售卖。用户承担账号与密码的保管责任，并就其账号及密码项下之一切活动负全部责任。
</p>

<p>
1.4禁止18岁及以下未成年人使用该平台，如未成年人使用该系统软件与客户直接或者间接发生矛盾冲突，该未成年人用户及相关人员负全部责任。
</p>

<p>
2.知识产权声明
</p>


<p>
2.1本《调律猫钢琴维护平台》软件是由调律猫钢琴维护平台团队开发。“调律猫钢琴维护平台”软件的一切版权、商标权、专利权、商业秘密等知识产权，以及相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩。界面设计、版面框架、相关数据、印刷材料、或电子文档等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其它知识产权法律法规的保护，除涉及第三方授权的软件或技术外，调律猫钢琴维护平台享有上述知识产权。
</p>

<p>
2.2未经调律猫钢琴维护平台所属公司的书面同意，用户不得以任何营利性或非营利性为目的自行实施、利用、转让或许可任何三方实施、利用、转让上述知识产权、调律猫钢琴维护平台所属公司保留追究上述未经许可行为的权利。
</p>

<p>3.授权范围</p>

<p>3.1用户经平台授权开通后台后，可在单一台终端设备上安装、使用、显示、运行后台相关功能，用户不可以对本软件或者本软件运行过程中释放到任何计算机终端内存中的数据以及本软件运行过程中客户端与服务器端的交互数据进行复制、更改、修改、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务器接入本软件和相关系统。
</p>

<p>3.2保留权利：未明确授权的其他一切权利仍归调律猫钢琴维护平台所有，用户使用其它权利时须另外取得调律猫钢琴维护平台的书面同意。</p>
<p>3.3本平台只授权用户使用规定服务功能，所有超出本平台授权外所发生的意外事件造成的一切财产损失或者人身安全均与本平台无关。</p>

<p>4.平台软件服务内容</p>

<p>
4.1平台软件系统提供服务的功能内容根据代理商与客户的具体情况而定，平台软件功能起到辅助、管理、监督的作用，代理商根据客户的钢琴使用情况，由代理商安排技术人员完成技术服务工作。

</p>


<p>
4.2平台提供的软件服务为有偿提供，平台会根据订单金额抽取相应的金额比例，但不影响用户后台资金以及后台功能使用、订单薪资结算等功能。
</p>

<p>
4.3用户理解，平台仅提供相关的功能辅助服务，除此之外，如用户遇到与后台无关的服务而产生其它费用，由用户自行负责。
</p>

<p>
5.软件服务变更，中断或终止
</p>

<p>
5.1鉴于本平台系统的特殊性，用户同意本平台有权随时变更、中断或终止部分功能或全部的本服务（包括收费服务及免费服务）。如变更、中断或终止的本服务属于免费服务，本平台公司无需通知用户，也无需对任何用户或第三方承担任何责任；如变更、中断或终止的本服务属于收费服务，本平台应当在变更、中断或终止之前事先通知用户，并应向受影响的用户提供等值的替代性收费服务，如用户不接受替代性收费服务，就该用户已经向平台支付的服务费，平台应当按照该用户的实际使用相应收费服务的情况扣除相应服务费之后将剩余的服务费退还该用户。
</p>

<p>
5.2本平台需要定期或不定期地对提供本服务的平台（如互联网网站、移动网络、后台等）或相关的设备进行检修或者维护，如因此类情况而造成收费服务在合理时间内的中断。平台无需为此承担任何责任，且除特殊情况外应当事先通告。
</p>


<p>
5.3如发生下列任何一种情形，本平台有权随时中断或者终止向用户提供本协议项下的服务【包括但不限于收费及免费的本服务（其中包括基于商业模式的免费服务）】而无需对用户或任何第三方承担责任：
</p>

<p>
5.3.1用户提供的个人资料的真实性、完整性、准确性、合法性、有效性存在问题；
</p>

<p>
5.3.2用户违反本协议中规定的使用规则；
</p>


<p>
5.3.3用户在使用收费服务时未按规定向平台支付相应的服务费。
</p>

<p>
5.4用户注册的账号昵称和姓名如存在违反法律法规或国家政策要求，或侵犯任何第三方合法权益的情况，平台有权禁止用户继续使用该账号、昵称与后台。
</p>


<p>
6.平台使用规则
</p>


<p>
6.1用户在申请使用平台软件时，必须向所属代理商或者平台经理提供准确、真实的个人相关资料，且需要经过平台的审核认证后方能开始使用软件，如个人资料有任何变动，必须及时更新。用户业绩资金与开通号码微信绑定，资金实时提现，合作期间长期收益，如违反平台任一规定或业绩超过一个月无上涨，平台有权终止合作。
</p>

<p>6.2用户不应将其账号、密码转让或出借予他人使用，由于用户的保管疏忽导致账号被盗、密码遭他人非法使用造成财产损失，本平台不承担任何责任。
</p>

<p>
6.3用户同意本平台有权在提供本服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息，并且，用户同意接受本平台通过电子邮件或其他方式向用户发送商品促销或其他相关商业信息。
</p>


<p>
6.4用户在使用该平台软件服务过程中，必须遵守以下原则：
</p>

<p>
6.4.1遵守平台规则，遵守中国有关法律法规；<br/>
6.4.2遵守所有与本服务有关的功能协议、网络协议、规定和程序；<br/>
6.4.3不得为任何非法目的而使用本平台软件，用户注销或终止使用时，禁止转走一切客户资料及一切与平台相关的资料信息；<br/>
6.4.4不得以任何形式使用本平台服务侵犯本平台的商业利益和进行不利于平台的行为，包括但不限于发布非本平台许可的商业或非商业广告；<br/>
6.4.5不得利用本平台服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；<br/>
6.4.6不得以调律猫钢琴维护平台的名义对外进行虚假宣传，不得传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的、或者其他任何违反公序良俗或非法的信息资料；
</p>



<p>
7交通安全与民事责任
</p>


<p>
7.1技术人员在服务路途中，应当自觉遵守《道路交通安全法》等法律法规，因交通事故造成的财产损失或者意外伤亡，由技术人员本人或直属代理商承担所有责任；<br/>
7.2技术人员如在服务过程中与第三方发生矛盾导致意外伤亡的，由技术人员承担全部民事责任或者刑事责任，与本平台无关；<br/>
7.3由于自身身体原因，技术人员如在服务工作过程中出现身体不适导致发生意外事件甚至意外身亡，由技术人员承担所有责任；与本平台无关；<br/>
</p>


<p>
8.隐私保护
</p>

<p>
8.1保护用户隐私是本平台的一项基本政策，本平台保证不对外公开或向第三方提供单个用户或客户资料及用户在使用本平台时储存在系统中的非公开内容，但下列情况除外：<br/>
8.1.1事先获得用户的明确同意；<br/>
8.1.2根据有关的法律法规要求；<br/>
8.1.3按照相关政府主管部门的要求；<br/>
8.1.4为维护社会公众的利益及平台的合法权益。<br/>
8.2本平台可能会与第三方合作向用户提供相关的服努，在此情况下，如该第三方同意承担与平台同等的保护用户隐私的责任，则平台有权将用户注册资料提供给该第三方。<br/>
8.3在不透露单个用户隐私资料的前提下，本平台有权对整个用户数据进行分析并对用户数据库进行商业上的利用。调律猫钢琴维护平台的《用户隐私政策》作为本协议的有效组成部分，且用户同意并接受我公司不定时的更新隐私政策，如用户不接受更新后的隐私政策，则应立即停止使用本平台服务。

</p>


<p>
9.纠纷处理：
</p>


<p>
9.1.1技术纠纷处理：遇到技术问题与技术人员产生纠纷或矛盾，应先通过代理商解决，如无法解决可致电平台技术部介入。<br/>
9.1.2价格纠纷处理：遇到关于价格问题自行向签约代理商协商解决，如无法协商，可向当地劳动部门申请介入，或者致电公司平台法律顾问进行协商处理。<br/>
9.1.3与代理商的纠纷：如在服务过程中与技术人员产生纠纷，应保留相关证据，并向代理商反应实情，由代理商协商解决。<br/>
9.1.4与代理商的纠纷：如与代理商产生合作纠纷，双方应协商解决，如无法商议解决，平台将按照平台规则或相关法律法规介入处理。<br/>
9.1.5其它纠纷处理：如遇其它纠纷无法协商处理，可向当地派出所介入处理，如个人或者机构但凡有损平台形象或利益等违规违法行为，造成平台损失，平台将按照相关法律对其提起诉讼，追究其法律责任，并赔偿平台一切经济损失。

</p>

<p>
10.免责声明
</p>


<p>
    10.1用户明确同意其使用本平台所存在的风险将完全由其自己承担;因其使用本平台服务而产生的一切后果也由其自己承担,除本协议另外约定外,本平台对用户不承担任何责任。<br/>
10.2本平台对服务不作任何类型担保，包括但不限于本服务的及时性、安全性、准确性、对在任何情况下使用或不能使用本平台服务所产生的直接、间接、偶然、特殊及后续的损害及风险，平台不承担任何责任。<br/>
10.3对不可抗力、计算机病毒、黑客攻击、系统不稳定、用户所在位置、用户关机以及其它任何网络、技术、通信线路等原因造成的服务中断或不能满足用户要求的风险，由用户自己承担，平台不承担任何责任。<br/>
10.4对于平台向用户提供的下列产品或者技术服务的缺陷和不足造成的任何损失，平台不承担任何责任。<br/>
10.4.1平台向用户免费提供的各项服务；<br/>
10.4.2平台向用户赠送的任何产品或者服务；<br/>
10.4.3平台向收费网络服务用户附赠的各种产品或者服务。<br/>
10.5用户发生的一切在协议范围内的纠纷与矛盾均属于自身或者签约代理商的责任，本平台不承担任何责任。<br/>
10.6如用户违反协议中任一条款，签约代理商或平台有权终止合作，并有权利取消该用户后台使用权。
</p>



<p>
11.违约赔偿
</p>


<p>
11.1如因平台违反有关法律法规或者本协议项下的责任范围内条款而给用户造成损失、平台同意由此造成的损害赔偿责任。<br/>
11.2用户同意保障和维护平台及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给平台或者任何第三人造成损失，用户同意承担由此造成的损害赔偿责任，包括但不限于返还押金并赔偿所有直接和间接损失。

</p>


<p>
12.协议修改
</p>



<p>
12.1平台有权随时修改本协议的任何条款，一旦本协议的内容发生变动，平台将会直接在平台网站上公布修改之后的协议内容，该公布行为视为平台已经通知用户修改内容，同时平台也可通过其他适当方式向用户提示修改内容。<br/>
12.2如果不同意平台对本协议相关条款所做的修改，用户应当停止使用本平台服务。如果用户继续使用本平台服务，则视为用户同意并接受平台对本协议相关条款所做的修改。

</p>

<p>
13.法律管辖
</p>


<p>
13.1本协议的订立、执行和解释及争议均受到中华人民共和国法律管辖，如一方有违反协议中任一条款，造成平台损失的，平台将追究其法律责任。<br/>
13.2如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向调律猫钢琴维护平台所属公司所在地的人民法院提起诉讼。

</p>

<p>
14技术服务要求与纠纷责任划分
</p>


<p>
14.1技术服务:上门调律服务必须佩戴平台工作证，统一工作服，穿鞋套。<br/>
14.1.1服务过程中，不拍照，不东张西望，不与客户多谈与技术无关话题。<br/>
14.1.2须按照技术流程完成技术工作服务。<br/>
14.1.3必须严谨对待客户钢琴技术工作，细心处理技术问题，耐心与客户沟通。<br/>
14.1.4工具必须按照要求摆放整齐，琴盖、上门板等拆下来后按照要求安全摆放整齐。<br/>
14.1.5钢琴如遇特殊情况需要增加费用，必须跟客户解释清楚，并得到客户认同确认才能进行额外增收费用，绝不允许服务价格内坐地起价，多收费用。<br/>
14.1.6如收到客户因技术或者价格等问题投诉，平台核查后，如情况属实，技术人员违反规定，我们将按照平台处罚制度进行相应处罚，如情节严重，平台将按照相关法律法规依法处理问题纠纷。
1  1.7如私自与客户派发名片或私加微信等违规行为，一经查实，将终止合作，并禁用后台。

</p>


<p>
15.其他规定</p>

<p>
15.1如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。<br/>
15.2本协议的标题仅为方便而设，在解释本协议时不应受标题的限制。<br/>
15.3本平台对此份协议享有最终解释权。</p>
                                <span slot="footer" class="dialog-footer">
                                    <el-button type="primary" @click="b">同意</el-button>
                                    <el-button @click="a">拒绝</el-button>
                                </span>
                                </el-dialog>
                     </el-form>
                
                  
                  
                  </el-col>
        </el-row>
   
    </div>
</template>

<script>
export default {
  data() {
      return{
          dialogVisible:false,
           checked: false,
           pic:'',
               form: {
              name: '',
              password:'',
              code:''
         
        }
      }
      
  },
  methods:{


      a(){
          this.dialogVisible = false
          this.checked = false
      },
      b(){
          
var vm = this
 vm.dialogVisible = false
    if(vm.form.name !=='' && vm.form.password!==''&& vm.form.code!==''){
            vm.$axios({
                method:"post",
                url:"/s/backend/api/userlogin",
                data:{
                    "code": vm.form.code,
                    "password": vm.form.password,
                    "phone": vm.form.name
                                }
                })
        .then(function(res){
               

               if(res.data.code== -1){
                    vm.$message({
                            message:res.data.msg,
                            type: 'error'
                                });
                      
               }else if(res.data.code== 0){
                     
                
                        if(res.data.result.userinfo.rolename=='管理员'){
                    vm.$message({
                            message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);
                        vm.$router.push({path: '/index/sy'})
            
            }else if(res.data.result.userinfo.rolename=='市场总监'){
    vm.$message({
                            message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index2/sy1'})
            }else if(res.data.result.userinfo.rolename=='市场业务经理'){
   vm.$message({
                            message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index3/sy1'})
            }else if(res.data.result.userinfo.rolename=='技术总监'){
                  vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index4/sy44'})
            }else if (res.data.result.userinfo.rolename=='技术专员'){
                 vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index5/sy55'})
            }else if(res.data.result.userinfo.rolename=='财务总监'){
  vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index6/sy66'})
            }else if (res.data.result.userinfo.rolename == '代理商'){
                 vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index7/sy'})
            }else if(res.data.result.userinfo.rolename == '财务专员'){
                 vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index9/sy'})
            }
               }
       
                
           
           

      
           
        })
        .catch(function(){
            
        })
           }else{
             vm.$message({
                            message:'请输入完整信息',
                            type: 'error'
                                });
           }
      },
      open(){  
       this.dialogVisible = true
      },
       go(){
           
    var vm = this
             vm.$axios({
                method:"post",
                url:"/s/backend/api/contract_info",
                data:{
                    "phone":vm.form.name,
                
                                }
                })
                    .then(res=>{
                        console.log(vm.form.name)
                        if(res.data.result == true){
                           
                            vm.dialogVisible = false
                                if(vm.form.name !=='' && vm.form.password!==''&& vm.form.code!==''){
            vm.$axios({
                method:"post",
                url:"/s/backend/api/userlogin",
                data:{
                    "code": vm.form.code,
                    "password": vm.form.password,
                    "phone": vm.form.name
                                }
                })
        .then(function(res){
               

               if(res.data.code== -1){
                    vm.$message({
                            message:res.data.msg,
                            type: 'error'
                                });
                      
               }else if(res.data.code== 0){
                     
                
                        if(res.data.result.userinfo.rolename=='管理员'){
                    vm.$message({
                            message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);
                        vm.$router.push({path: '/index/sy'})
            
            }else if(res.data.result.userinfo.rolename=='市场总监'){
    vm.$message({
                            message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index2/sy1'})
            }else if(res.data.result.userinfo.rolename=='市场业务经理'){
   vm.$message({
                            message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index3/sy1'})
            }else if(res.data.result.userinfo.rolename=='技术总监'){
                  vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index4/sy44'})
            }else if (res.data.result.userinfo.rolename=='技术专员'){
                 vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index5/sy55'})
            }else if(res.data.result.userinfo.rolename=='财务总监'){
  vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index6/sy66'})
            }else if (res.data.result.userinfo.rolename == '代理商'){
                 vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index7/sy'})
            }else if(res.data.result.userinfo.rolename == '财务专员'){
                 vm.$message({   message:'登录成功',
                            type: 'success'
                                });
     
                           localStorage.setItem("token",res.data.result.token);
                           localStorage.setItem("jobnumber",res.data.result.userinfo.jobnumber);

                        vm.$router.push({path: '/index9/sy'})
            }
               }
       
                
           
           

      
           
        })
        .catch(function(){
            
        })
           }else{
             vm.$message({
                            message:'请输入完整信息',
                            type: 'error'
                                });
           }
                        }else{
                          
                         
                       vm.dialogVisible = true
                        }
                    }).catch({

                    })
 
  
       
       },
      changeico(){
               var that=this;
            this.$axios({
            method:"get",
            url:"/s/backend/code"
             })
        .then(function(res){

                that.pic =  res.data.url
 
                //   'https://gqtlgj.com/'
           
        })
        .catch(function(){
           
        })
      },
      handlerPageNo(){
       
      }
  },
  mounted(){
        
        window.localStorage.removeItem('token')
          window.localStorage.removeItem('jobnumber')
      var that=this;  
      that.$axios.get("/s/backend/code")

        .then(res=>{
        that.pic = res.data.url
        })
        .catch(function(){
           
        })

  }
}
</script>

<style scoped>
.dis{
    display: flex;
    align-items: center;
    border: 1px solid #DFE0E8;
    margin-bottom: 20px;
}
.dis input{
    border: none;
    width: 200px;
    padding-left: 20px;
}

.dis p {
    padding: 0;
    margin: 0;font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #293460;
}
    .logins{
        background: url('../assets/login_b.png') no-repeat bottom;
        background-size: 100% 100%;
        height: 100vh;
     
    }
    .logins1{
        height: 100vh;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .logins1 .containers{
        text-align: center;
    }
    /deep/ .el-input__inner{
        border:none;
        padding-left:25px ;
            color:#000;
    }

    /deep/.el-dialog__footer{
    text-align: center;
}
    /deep/.el-dialog {
    margin-top:3vh!important
}
    .el-dialog__body p{
        line-height: 1.7;
        margin-bottom: 10px;
        text-align: left;
        font-size: 12px ;
    }

    /deep/.el-dialog{
   width: 100%; height:600px;overflow-y: scroll;
    }
</style>